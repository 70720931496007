import {
  axiosInstanceV1,
  dynamicAxiosInstance,
  getReqConfig,
} from './axiosInstance';
import { parseResponse } from './helper';

const BASE = '/Games';

export async function getGames_req(
  cookies,
  ViewCount,
  Page = 1,
  providerID,
  categoryID,
  typeID,
  IsMobile
) {
  return parseResponse(
    axiosInstanceV1.get(BASE, {
      ...getReqConfig(cookies),
      params: {
        ViewCount,
        Page,
        providerID,
        categoryID,
        typeID,
        IsMobile,
      },
    })
  );
}

export async function initGame_req(gameKey) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/Init`,
      {
        gameKey,
      },
      getReqConfig()
    )
  );
}

export async function initGameWithUrl_req(initUrl, gameKey) {
  return parseResponse(
    dynamicAxiosInstance.post(
      initUrl,
      {
        gameKey,
      },
      getReqConfig()
    )
  );
}

export async function initDemoGame_req(gameKey) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/InitDemo`,
      {
        gameKey,
      },
      getReqConfig()
    )
  );
}

export async function getGame_req(cookies, gameKey) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/Get/${gameKey}`, getReqConfig(cookies))
  );
}

export async function searchGames_req(searchValue, isMobile) {
  return parseResponse(
    axiosInstanceV1.get('/Search', {
      params: {
        q: searchValue,
        isMobile,
      },
    })
  );
}
