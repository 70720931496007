import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';

import classNames from 'classnames';

import styles from './link.wrapper.scss';

export default function LinkWrapper({
  children,
  className,
  href,
  as,
  target,
  privateRoute,
  onClick,
}) {
  const id = useSelector((state) => state.user.id);
  const router = useRouter();

  const { locale } = router;

  if (!id && privateRoute) {
    return (
      <>
        <Link href={`/login?from=${as || href}`} as="/login" locale={locale}>
          <a
            target={target}
            className={classNames('link_wrapper', className)}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
          >
            {children}
          </a>
        </Link>
        <style jsx>{styles}</style>
      </>
    );
  }

  return (
    <>
      <Link href={href} as={as || href} locale={locale}>
        <a
          target={target}
          className={classNames('link_wrapper', className)}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          {children}
        </a>
      </Link>
      <style jsx>{styles}</style>
    </>
  );
}
